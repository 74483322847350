import { HTMLAttributes } from 'react';

const Badge = ({
  children,
  className = '',
  color = 'blue',
  ...props
}: {
  color: 'blue' | 'gray';
} & HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={`w-fit px-2 py-1.5 font-nimbus-sans-extd-d text-[10px] leading-none text-white ${
        color === 'gray' ? 'bg-[rgba(102,102,101)]' : 'bg-blue'
      } ${className}`}
    >
      {children}
    </span>
  );
};

export default Badge;
