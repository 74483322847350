import { useStoreContext } from 'contexts';
import laggy from 'lib/swr/laggy';
import { keys, set as setLocalStorage, get as getLocalStorage } from 'lib/utils/localStorage';
import useSWR from 'swr';

const initialProductIds = [];

const useLocalWishlist = () => {
  const { store } = useStoreContext();
  const key = keys.wishlist(store);

  const { data, error, mutate } = useSWR(
    ['wishlistProductIds'],
    () => {
      const reset = () => {
        setLocalStorage(key, JSON.stringify(initialProductIds));
        return initialProductIds;
      };

      const value = getLocalStorage(key);

      if (!value) return reset();

      try {
        return JSON.parse(value);
      } catch {
        return reset();
      }
    },
    { use: [laggy] }
  );

  const productIds = data || [];

  return {
    productIds,
    count: productIds.length,
    clear: () => {
      setLocalStorage(key, JSON.stringify([]));
      mutate();
    },
    add: newProductIds => {
      setLocalStorage(key, JSON.stringify([...newProductIds, ...productIds]));
      mutate();
    },
    remove: removeProductIds => {
      setLocalStorage(key, JSON.stringify(productIds.filter(id => !removeProductIds.includes(id))));
      mutate();
    },
    isLoading: Boolean(!error && !data),
  };
};

export default useLocalWishlist;
