import { parseMetafields } from './fetchCollectionProducts';
import { LIST_PRODUCT } from './fragments';
import retryFetch from './retryFetch';

const query = `
  query multipleProducts($ids: [ID!]!){
    nodes(ids: $ids){
      ... on Product {
        ${LIST_PRODUCT}
      }
    }
  }
`;

const getProducts =
  store =>
  async (ids, preview = false) => {
    const {
      data: { nodes },
    } = await retryFetch(store, {
      query,
      variables: { ids },
    });

    return nodes
      .filter(Boolean)
      .map(parseMetafields)
      .filter(({ tags }) => preview || tags.includes('contentfulStatus:PUBLISHED'));
  };

export default getProducts;
