/* eslint-disable import/prefer-default-export */
const SIZES_ORDER = [
  'XXS',
  'XXS/XS',
  'XS',
  'XS/S',
  'S',
  'S/M',
  'M',
  'M/L',
  'L',
  'L/XL',
  'XL',
  'XL/XXL',
  'XXL',
  'XXL/XXXL',
  'XXXL',
];

const sortByArray = sortingArray => array =>
  array.slice().sort((a, b) => sortingArray.indexOf(a) - sortingArray.indexOf(b));
const sortSizes = sortByArray(SIZES_ORDER);
const numbersSorting = (a, b) => a - b;

export const sortMixedOptions = array => [
  ...array.filter(Number).map(Number).sort(numbersSorting).map(String),
  ...sortSizes(array.filter(item => Number.isNaN(Number(item)))),
];
