import useSWRImmutable from 'swr/immutable';
import getProducts from 'lib/shopify/getProducts';
import laggy from 'lib/swr/laggy';
import { useStoreContext } from 'contexts';

/**
 * @param {import('swr').SWRConfiguration} config
 */
const useWishlistProducts = (productIds, config = {}) => {
  const { store } = useStoreContext();

  const { data, mutate, error } = useSWRImmutable(
    ['wishlist-products', ...productIds],
    async (_, ...ids) => (ids.length > 0 ? getProducts(store)(ids) : []),
    {
      use: [laggy],
      ...config,
    }
  );

  const products = data ? data.sort((a, b) => a.productTitle.localeCompare(b.productTitle)) : [];
  const isLoading = !data && !error;

  return {
    products,
    mutate,
    isLoading,
  };
};
export default useWishlistProducts;
