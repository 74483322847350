const TopRightIndicator = ({ children }) => (
  <text
    className="block text-[10px]"
    x="82.5%"
    y="12.5%"
    fill="currentColor"
    dominantBaseline="middle"
    textAnchor="left"
  >
    {children}
  </text>
);

export const Search = ({ className = '' }) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.073 16.645a5.573 5.573 0 1 0 0-11.145 5.573 5.573 0 0 0 0 11.145ZM15.252 15.252l3.25 3.25"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Cart = ({ className = '', children }) => (
  <svg
    className={`${className} overflow-visible`}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <TopRightIndicator>{children}</TopRightIndicator>
    <path
      d="M6.547 18h10.906a.43.43 0 0 0 .282-.107.454.454 0 0 0 .149-.269l1.31-8.1a.463.463 0 0 0-.098-.365.438.438 0 0 0-.333-.159H5.237a.426.426 0 0 0-.334.16.455.455 0 0 0-.097.364l1.31 8.1c.017.105.07.2.149.27a.43.43 0 0 0 .282.106Z"
      stroke="currentColor"
    />
    <path
      d="M8.4 9V7.5c0-.398.152-.78.422-1.06.27-.282.636-.44 1.018-.44h4.32c.382 0 .748.158 1.018.44.27.28.422.662.422 1.06V9"
      stroke="currentColor"
    />
  </svg>
);

export const ArrowDown = ({ className }) => (
  <svg
    className={className || ''}
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L4.99992 4.99992L8.99984 1" stroke="currentColor" />
  </svg>
);

export const ArrowLeft = ({ className }) => (
  <svg
    className={`rotate-90 ${className || ''}`}
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L4.99992 4.99992L8.99984 1" stroke="#041C2C" />
  </svg>
);

export const Globe = ({ className }) => (
  <svg
    className={className || ''}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7.5" cy="7.5" r="6.5" stroke="#041C2C" strokeLinejoin="round" />
    <path d="M14 7.5H1" stroke="#041C2C" strokeLinejoin="round" />
    <path
      d="M12.1914 12C11.0085 10.7673 9.34433 10 7.50097 10C5.65762 10 3.99348 10.7673 2.81055 12"
      stroke="#041C2C"
      strokeLinejoin="round"
    />
    <path
      d="M12.1914 3C11.0085 4.23267 9.34433 5 7.50097 5C5.65762 5 3.99348 4.23267 2.81055 3"
      stroke="#041C2C"
      strokeLinejoin="round"
    />
    <ellipse cx="7.5" cy="7.5" rx="3.5" ry="6.5" stroke="#041C2C" strokeLinejoin="round" />
    <path d="M7.5 1V14" stroke="#041C2C" strokeLinejoin="round" />
  </svg>
);

export const Close = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
    fill="currentColor"
  >
    <path d="M4.707 3.293L3.293 4.707 10.586 12l-7.293 7.293 1.414 1.414L12 13.414l7.293 7.293 1.414-1.414L13.414 12l7.293-7.293-1.414-1.414L12 10.586 4.707 3.293z" />
  </svg>
);

export const Close2 = ({ className }) => (
  <svg
    className={className}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.178 15.6902L17.824 19.3372L18.532 18.6302L14.885 14.9832L18.532 11.3372L17.825 10.6292L14.178 14.2762L10.532 10.6292L9.82495 11.3372L13.471 14.9832L9.82495 18.6292L10.532 19.3372L14.178 15.6902Z"
      fill="currentColor"
    />
  </svg>
);

export const ChevronLeft = ({ className = '' }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 36 36"
    fill="none"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m20.25 24-6-6 6-6"
    />
  </svg>
);

export const Close36 = ({ className = '' }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 36 36"
    fill="none"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m13 13 10 10m-10 0 10-10"
    />
  </svg>
);

export const Spinner = ({ className = '' }) => (
  <svg
    role="status"
    className={`animate-spin ${className}`}
    viewBox="0 0 100 100"
    enableBackground="new 0 0 0 0"
  >
    <path
      fill="currentColor"
      d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
    />
  </svg>
);

export const Check = ({ className = '' }) => (
  <svg
    className={`h-[8px] w-[10px] ${className}`}
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.05076 0.352919C8.10391 0.289897 8.16734 0.239832 8.23735 0.205653C8.30735 0.171474 8.38252 0.15387 8.45846 0.15387C8.53439 0.15387 8.60956 0.171474 8.67957 0.205653C8.74957 0.239832 8.81301 0.289897 8.86616 0.352919C9.0889 0.614476 9.09201 1.03713 8.87395 1.30321L4.2689 7.62945C4.21662 7.69617 4.15317 7.74979 4.08244 7.78704C4.01171 7.82428 3.93518 7.84437 3.85754 7.84606C3.7799 7.84775 3.70278 7.83102 3.6309 7.79689C3.55902 7.76276 3.49388 7.71194 3.43948 7.64755L0.637354 4.34777C0.529285 4.21969 0.46875 4.04747 0.46875 3.8681C0.46875 3.68872 0.529285 3.5165 0.637354 3.38843C0.690504 3.3254 0.753939 3.27534 0.823946 3.24116C0.893952 3.20698 0.969122 3.18938 1.04506 3.18938C1.12099 3.18938 1.19616 3.20698 1.26617 3.24116C1.33617 3.27534 1.39961 3.3254 1.45276 3.38843L3.82966 6.18772L8.03518 0.37283C8.04002 0.365835 8.04522 0.359186 8.05076 0.352919Z"
      fill="currentColor"
    />
  </svg>
);

export const Filter = ({ className }) => (
  <svg
    className={`h-[7px] w-[16px] ${className}`}
    viewBox="0 0 16 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3119 5.83398H5.40123C5.27099 5.83398 5.14608 5.8852 5.05399 5.97638C4.96189 6.06755 4.91016 6.19121 4.91016 6.32015C4.91016 6.44908 4.96189 6.57274 5.05399 6.66391C5.14608 6.75509 5.27099 6.80631 5.40123 6.80631H10.3119C10.4422 6.80631 10.5671 6.75509 10.6592 6.66391C10.7513 6.57274 10.803 6.44908 10.803 6.32015C10.803 6.19121 10.7513 6.06755 10.6592 5.97638C10.5671 5.8852 10.4422 5.83398 10.3119 5.83398Z"
      fill="#041C2C"
    />
    <path
      d="M12.7676 2.91699H2.94615C2.81591 2.91699 2.691 2.96821 2.59891 3.05938C2.50682 3.15056 2.45508 3.27422 2.45508 3.40315C2.45508 3.53209 2.50682 3.65575 2.59891 3.74692C2.691 3.83809 2.81591 3.88931 2.94615 3.88931H12.7676C12.8978 3.88931 13.0227 3.83809 13.1148 3.74692C13.2069 3.65575 13.2586 3.53209 13.2586 3.40315C13.2586 3.27422 13.2069 3.15056 13.1148 3.05938C13.0227 2.96821 12.8978 2.91699 12.7676 2.91699Z"
      fill="#041C2C"
    />
    <path
      d="M15.2232 0H0.491071C0.360831 0 0.235925 0.0512245 0.143831 0.142405C0.0517377 0.233585 0 0.357251 0 0.486199C0 0.615147 0.0517377 0.738814 0.143831 0.829994C0.235925 0.921174 0.360831 0.972398 0.491071 0.972398H15.2232C15.3535 0.972398 15.4784 0.921174 15.5705 0.829994C15.6625 0.738814 15.7143 0.615147 15.7143 0.486199C15.7143 0.357251 15.6625 0.233585 15.5705 0.142405C15.4784 0.0512245 15.3535 0 15.2232 0Z"
      fill="#041C2C"
    />
  </svg>
);

export const Heart = ({ className = '', children }) => (
  <svg
    className={`${className} overflow-visible`}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <TopRightIndicator>{children}</TopRightIndicator>
    <path
      d="M11.495 7.105A3.493 3.493 0 0 0 8.97 6a3.491 3.491 0 0 0-2.53 1.091A3.85 3.85 0 0 0 5.4 9.746a3.847 3.847 0 0 0 1.053 2.65l5.178 5.435a.537.537 0 0 0 .389.169c.146 0 .286-.06.389-.169l5.152-5.403A3.85 3.85 0 0 0 18.6 9.774a3.847 3.847 0 0 0-1.053-2.65 3.572 3.572 0 0 0-1.159-.817 3.433 3.433 0 0 0-2.74-.008 3.57 3.57 0 0 0-1.162.812l-.492.517-.5-.523Z"
      stroke="currentColor"
    />
  </svg>
);

export const Mail = ({ className = '' }) => (
  <svg
    className={className}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="#fff" d="M4.5 7h15v10h-15z" />
    <path d="M4.5 7h15l-7.508 6.17L9.5 11l-5-4Z" fill="#fff" />
    <path
      d="M4.5 7v10h14.883M4.5 7h15m-15 0 5 4 2.492 2.17L19.5 7m0 10V7"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Muted = ({ className = '' }) => (
  <svg
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="1"
    strokeLinecap="square"
    strokeLinejoin="miter"
    fill="none"
    className={className}
  >
    <path d="M18 10L22 14M18 14L22 10" />
    <path d="M13 5v14l-5-4H3V9h5z" />
    <path strokeLinecap="round" d="M13 14c1.5-1 1.5-3 0-4" />
  </svg>
);

export const Unmuted = ({ className = '' }) => (
  <svg
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="1"
    strokeLinecap="square"
    strokeLinejoin="miter"
    fill="none"
    className={className}
  >
    <path d="M13 5v14l-5-4H3V9h5z" />
    <path strokeLinecap="round" d="M13 14c1.5-1 1.5-3 0-4" />
    <path d="M16 16C18.0858253 13.9141747 18.0858253 10.0858253 16 8M18 19C21.98552 15.01448 22.0076803 9.00768033 18 5" />
  </svg>
);
