import { useStoreContext } from 'contexts';
import laggy from 'lib/swr/laggy';
import { distinct } from 'lib/utils/helpers';
import useSWR from 'swr';

const fetcher = store => async url => fetch(url, { headers: { store } }).then(r => r.json());

const updateWishlistProducts =
  store =>
  (addProductIds = [], removeProductIds = []) =>
    fetch('/api/wishlist/update', {
      method: 'POST',
      body: JSON.stringify({ addProductIds, removeProductIds }),
      headers: {
        store,
        'Content-Type': 'application/json',
      },
    });

const useCustomerWishlist = (enabled = false) => {
  const { store } = useStoreContext();

  const { data, mutate, error } = useSWR(
    [`/api/wishlist`, enabled],
    enabled ? fetcher(store) : () => false,
    {
      use: [laggy],
    }
  );

  const isLoading = Boolean(!error && !data);
  const productIds = data || [];

  const add = async newProductIds => {
    mutate(updateWishlistProducts(store)(newProductIds), {
      populateCache: (_, ids) => distinct([...newProductIds, ...ids]),
      optimisticData: distinct([...newProductIds, ...productIds]),
      rollbackOnError: true,
    });
  };

  const remove = removeProductIds =>
    mutate(updateWishlistProducts(store)([], removeProductIds), {
      populateCache: (_, ids) => ids.filter(id => !removeProductIds.includes(id)),
      optimisticData: productIds.filter(id => !removeProductIds.includes(id)),
      rollbackOnError: true,
      revalidate: false,
    });

  return {
    productIds,
    count: productIds.length,
    add,
    remove,
    isLoading,
  };
};

export default useCustomerWishlist;
