/* Increase touch area for mobile with invisible element, make sure parent has relative */
const MobileTouchArea = () => (
  <div
    style={{ '--minTouchSquare': '48px' }}
    className={`absolute left-[min(0px,calc((100%-var(--minTouchSquare))/2))] 
top-[min(0px,calc((100%-var(--minTouchSquare))/2))] 
right-[min(0px,calc((100%-var(--minTouchSquare))/2))] 
bottom-[min(0px,calc((100%-var(--minTouchSquare))/2))] 
md:hidden`}
  />
);

export default MobileTouchArea;
