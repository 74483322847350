import { useRef, useEffect, useCallback } from 'react';

// This is a SWR middleware for keeping the data even if key changes.
// https://swr.vercel.app/docs/middleware

function laggy(useSWRNext) {
  return (key, fetcher, config) => {
    const laggyDataRef = useRef();

    const swr = useSWRNext(key, fetcher, config);

    useEffect(() => {
      if (swr.data !== undefined) {
        laggyDataRef.current = swr.data;
      }
    }, [swr.data]);

    const resetLaggy = useCallback(() => {
      laggyDataRef.current = undefined;
    }, []);

    const dataOrLaggyData = swr.data === undefined ? laggyDataRef.current : swr.data;
    const isLagging = swr.data === undefined && laggyDataRef.current !== undefined;

    return { ...swr, data: dataOrLaggyData, isLagging, resetLaggy };
  };
}

export default laggy;
