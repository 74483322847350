import { useState } from 'react';
import classNames from 'classnames';
import { useUI } from 'hooks';
import { getLinkHref } from 'lib/utils/helpers';
import { sortMixedOptions } from 'lib/utils/optionsSorting';
import getOptionValues from 'lib/shopify/getOptionValues';
import getAvailableOptions from 'lib/shopify/getAvailableOptions';
import { Image, PriceLabel, SiteLink } from 'components/shared';
import { useSpring, animated } from 'react-spring';
import springConfig from 'lib/utils/springConfigs';
import { trackEvents, trackInWindow } from 'hooks/tracking/track';
import useWishlist from 'hooks/swym/useWishlist';
import toItem from 'hooks/tracking/to-item';
import Badge from 'components/shared/badge';
import { Heart } from './icons/ui';
import MobileTouchArea from './mobile-touch-area';

const Product = ({
  className = '',
  style,
  product,
  t,
  priority = false,
  minimal = false,
  onClick,
  trackRecommendedProduct,
  viewWidths = [85, 45],
}) => {
  const { toggleQuickShop } = useUI();
  const { toggle, isIn, isLoadingProduct } = useWishlist();
  const [viewWidth, viewWidthDesktop] = viewWidths;

  const isInWishlist = isIn(product.id);
  const toggleWishlist = () => toggle(product);
  const isLoadingWishList = isLoadingProduct(product.id);

  const {
    productTitle,
    comingSoon,
    genericFit,
    badge,
    slug,
    thumbnailImage,
    secondThumbnailImage,
  } = product;

  const hasQuickShop = false; // product.hasQuickShop
  const hasGenericFit = Boolean(genericFit) && genericFit !== '_';

  const genericFitLabel = t(`filter options - fit - ${genericFit}`, genericFit);
  const quickshopLabel = t('product - quickShop', 'Quick shop');

  const title = (
    <>
      {productTitle}
      {hasGenericFit && <> - {genericFitLabel}</>}
    </>
  );

  const options = getOptionValues(product);
  const availableOptions = getAvailableOptions(product);

  const isAvailable = (option, value) =>
    availableOptions.find(availableOption => availableOption[option] === value);

  const [hover, setHover] = useState(false);

  const currencyCode = product.priceRange?.maxVariantPrice?.currencyCode;
  const price = product.priceRange?.maxVariantPrice?.amount;
  const maxComparePrice = product.compareAtPriceRange?.maxVariantPrice?.amount;
  const comparePrice = maxComparePrice > 0 && maxComparePrice !== price ? maxComparePrice : null;

  const imageSpring = useSpring({
    transform: hover ? 'scale(1.0025)' : 'scale(1.03)',
    opacity: hover ? 1 : 0,
    config: hover ? springConfig.easeInButBetter : springConfig.sizeWrapperPDP,
  });

  const priceLabel = (
    <PriceLabel currencyCode={currencyCode} price={price} compareAtPrice={comparePrice} />
  );

  const optimizeThumbnail = image =>
    image ? { ...image, url: `${image.url}?q=80&fit=scale&w=1600` } : null;

  const thumbnailSizes = `(max-width: 767px) ${viewWidth.toFixed(2)}vw,
  (min-width: 768px) ${viewWidthDesktop.toFixed(2)}vw`;

  return (
    <div
      style={style}
      className={className}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="relative">
        <SiteLink
          className="relative block overflow-hidden"
          href={getLinkHref({ __typename: 'PageProduct', slug })}
          onClick={() => {
            if (onClick) onClick(product);

            trackInWindow({
              event: trackRecommendedProduct
                ? trackEvents.productClickInCarousel
                : trackEvents.productClick,
              page_path: getLinkHref({ __typename: 'PageProduct', slug }),
              page_title: document.title,
              items: [toItem(product)],
            });
          }}
        >
          <Image
            alt={productTitle}
            className="w-full pt-[120%]"
            src={optimizeThumbnail(thumbnailImage)}
            sizes={thumbnailSizes}
            layout="fill"
            priority={priority}
          />
          {!hasQuickShop && secondThumbnailImage && (
            <animated.div
              style={{ ...imageSpring }}
              className="pointer-events-none absolute left-0 top-0 hidden h-full w-full will-change-[opacity,_transform] md:block"
            >
              <Image
                alt=""
                src={optimizeThumbnail(secondThumbnailImage)}
                sizes={thumbnailSizes}
                layout="fill"
                bgColor={false}
              />
            </animated.div>
          )}
        </SiteLink>
        {hasQuickShop && hover && (
          <button
            type="button"
            onClick={() => toggleQuickShop(product)}
            className="absolute bottom-[24px] left-1/2 z-10 hidden min-w-[50%] -translate-x-1/2 items-center justify-center bg-white p-[12px] font-nimbus-sans-extd-d text-[12px] uppercase md:flex"
          >
            {quickshopLabel}
          </button>
        )}
        {(badge || comingSoon) && (
          <div className="pointer-events-none absolute -bottom-2.5 left-0 z-10 flex w-full flex-col items-center">
            {comingSoon ? (
              <Badge className="pointer-events-auto">{t('Coming soon badge', 'Coming Soon')}</Badge>
            ) : (
              <Badge className="pointer-events-auto" color="gray">
                {badge}
              </Badge>
            )}
          </div>
        )}
        <button
          type="button"
          onClick={() => toggleWishlist()}
          className="absolute right-0 top-0 p-3"
          disabled={isLoadingWishList}
        >
          <MobileTouchArea />
          <Heart className={`w-5 ${isInWishlist ? 'fill-current' : ''}`} />
        </button>
      </div>
      {minimal ? (
        <p className="p-2 text-center text-xs">{priceLabel}</p>
      ) : (
        <>
          <div
            className={classNames(
              'mt-[16px] hidden h-[40px] flex-col items-center justify-center text-center text-[12px] md:flex md:h-[50px]',
              {
                'flex-wrap gap-x-[40px] px-[16px]': hover,
              }
            )}
          >
            {hover ? (
              Object.entries(options).map(([option, values]) => (
                <p className="flex items-center gap-[4px]" key={option}>
                  {t(`filter - ${option.toLowerCase()}`) && (
                    <span className="mt-[-2px] font-bold">
                      {t(`filter - ${option.toLowerCase()}`)}
                    </span>
                  )}
                  {sortMixedOptions(
                    values.map(value => (
                      <span
                        key={value}
                        className={`${
                          isAvailable(option, value) && !comingSoon
                            ? ''
                            : 'text-[#E0E0E0] line-through'
                        }`}
                      >
                        {value}
                      </span>
                    ))
                  )}
                </p>
              ))
            ) : (
              <>
                <p>{title}</p>
                <p>{priceLabel}</p>
              </>
            )}
          </div>
          <div className="mt-4 flex h-[40px] flex-col items-center justify-center px-1 text-center text-xs md:hidden md:h-[50px]">
            <p>{title}</p>
            <p>{priceLabel}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default Product;
