import { distinct, stackEntries } from 'lib/utils/helpers';

const getOptionValues = shopifyProduct => {
  const entries = shopifyProduct.variants.edges
    .flatMap(({ node: { selectedOptions } }) => selectedOptions)
    .map(({ name, value }) => [name, value]);

  return Object.fromEntries(
    Object.entries(stackEntries(entries))
      .map(([option, values]) => [option, distinct(values)])
      .filter(([option]) => ['Size', 'Waist', 'Length'].includes(option))
  );
};

export default getOptionValues;
